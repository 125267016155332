.Navbar {
  height: 140px;
  border-bottom: solid 3px var(--bordersecondary-color) !important;
}

.SubNavbar label {
  border-radius: 0;
}

.btn-check:checked+.btn,
.btn-check,
.btn-text:hover {
  border: none !important;
}

.nav-link {
  height: 140px;
  display: flex !important; 
  align-items: center !important;
  color: var(--text-color) !important;
}


.nav-link-active,
.nav-link:hover,
.btn:active,
.btn-text:hover {
  border-bottom: solid 2px var(--text-color) !important;
  transition: ease-in;
}

.nav-link-active {
  font-weight: 700 !important;
}

@media only screen and (max-width: 980px) {
  .Navbar {
    height: auto;
  }

  .nav-link {
    height: 50px;
  }
}

.bg-white{
  background-color: var(--bkg-color) !important;
}