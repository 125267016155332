 .wrapper-class {
   height: 90%;
 }

 .editor-class {
   background-color: white;
   padding: 1rem;
   border: 1px solid var(--bkgterciary);
   margin: 0.5em;
   height: 90%;
   font-family: 'Open Sans';
 }

 .toolbar-class {
   text-align: center;
   margin: 0.5em;
   padding: 0.5em;
 }


 .styleButton#bold {
   font-weight: bold;
 }

 .styleButton#italic {
   font-style: italic;
   font-family: serif;
 }

 .styleButton#underline {
   text-decoration: underline;
 }

 .button {
   width: 40px;
   height: 40px;
 }

 .text-active {
   background-color: var( --bkr-blue) !important;
   color: var(--bkg-color) !important;
 }