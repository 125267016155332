.App {
  margin: 0;
  width: 100%;
  border-top: solid 4px var(--border-body-color) !important;
  color: var(--text-color);
}

.Embedded-App {
  margin: 0;
  width: 100%;
  color: var(--text-color);
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

/* Scrollbar  */
::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: var(--bkgsecondary);
}

::-webkit-scrollbar-thumb {
  background: #c8c8c8;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bkgterciary);
}

.loader-wrapper {
  align-items: center;
  background-color: var(--bkg-color);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: -1;
}

path:active,
.active {
  fill: var(--bkr-locations-DNU) !important;
}


td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-paddingNone b {
  display: none;
}



