.dashed-border {
  border: dashed 2px var(--lines-color) !important;
}

.card-borderless {
  border: none !important;
  width: 155px;
}

.title-card {
  font-size: 12px;
}

.highlight {
  border-color: var(--bkr-green) !important;
}

.card-img-top {
  position: relative;
  height: 90px;
}

.progress {
  position: absolute;
  left: 0;
  right: 0;
  top: 20%;
  margin: auto;
}

progress[value] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 20px !important;
  height: 5px !important;
  width: 50px !important;
}

progress[value]::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25) inset;
}

progress[value]::-webkit-progress-value {
  background-color: var(--bkr-green);
  border-radius: 20px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.yarl__slide_image:hover {
  cursor: pointer;
}

progress::-moz-progress-bar {
  border-radius: 20px;
}
#galleryImg > div > img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 980px) {
}
