@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  font-family: "Inter", sans-serif !important;
}
html {
  height: 100%;
}

body, :fullscreen {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: auto;
  background-color: var(--bkg-color) !important;
}

/* user agent stylesheet */
:not(:root):fullscreen::backdrop {
    background-color: var(--bkg-color) !important;
}

body:fullscreen {
  overflow: scroll !important;
}
body:-ms-fullscreen {
  overflow: scroll !important;
}
body:-webkit-full-screen {
  overflow: scroll !important;
}
body:-moz-full-screen {
  overflow: scroll !important;
}