.container-calculator {
    height: 95%
}
.wrapper {
    width: 100%;
    height: 325px;
    padding: 10px;
    border-radius: 10px;
    background-color:  var(--bkgsecondary);
    border: 1px solid var(--bkgterciary);
  }

  .screen {
    height: 50px;
    width: 100%;
    margin-bottom: 10px;
    padding: 0 10px;
    background-color: var(--bkg-color);
    border-radius: 10px;
    border: 1px solid var(--bkgterciary);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: black;
    font-weight: bold;
    box-sizing: border-box;
  }

  .buttonBox {
    width: 100%;
    height: calc(100% - 60px);
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-gap: 10px;
  }

  .buttonCalc {
    border: none;
    background-color: #a7b1b5;
    font-size: 24px;
    color: var(--text-color);
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
  }
  
  .buttonCalc:hover {
    background-color: #8c9497;
  }
  
  .equals {
    border: none;
    font-size: 24px;
    color: var(--text-color);
    font-weight: bold;
    cursor: pointer;
    border-radius: 10px;
    outline: none;
    background-color: var(--bkr-red);
  }
  
  .equals:hover {
    background-color: var(--bkr-red);
  }

  .backCalc {
    background-color: #e9ecef;
  }