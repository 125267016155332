.right {
  color: var(--bkr-green);
}

.wrong {
  color: var(--bkr-red);

}

.inprogress {
  color: var(--bkr-blue);

}

.pending {
  color: #aaa;
}

.expired-notice {
  text-align: center;
  padding: 2rem;
  /* border: 1px solid #ebebeb;
    border-radius: 0.25rem; */
  margin: 0.5rem;
}

.expired-notice>span {
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--bkr-red);
}

.expired-notice>p {
  font-size: 1.5rem;
}

.timer-container {
  background-color: #ffffff;
  border-top-left-radius: 4px; 
  border-top-right-radius: 4px; 
};

.default-shadow { 
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

.show-counter {
  padding: 0.1rem;
  background-color: #212529;
  width: 30%;
  /* border-radius: 60px; */
}

.show-counter .countdown-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 1.25rem;
  /* line-height: 1.75rem; */
  padding: 0.5rem;
  /* border: 1px solid #ebebeb;
    border-radius: 0.25rem; */
  text-decoration: none;
  color: white;
  ;
}

.show-counter .countdown {
  line-height: 1.25rem;
  padding: 0 0.75rem 0 0.75rem;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.show-counter.danger {
  background-color: var(--bkr-red);
}

.show-counter .countdown.danger {
  background-color: var(--bkr-red);
}

.show-counter .countdown>p {
  margin: 0;
}

.show-counter .countdown>span {
  text-transform: uppercase;
  font-size: 0.75rem;
  line-height: 1rem;
}

.page_pending {
  background-color: #1976d2 !important;
  border-radius: 4px !important;
  margin: 4px;
}

.page_empty {
  background-color: #212529 !important;
  border-radius: 4px !important;
  margin: 4px;
}

.page_completed {
  background-color: #198754 !important;
  color: white !important;
  border-radius: 4px !important;
  margin: 4px;
}

.page_tagged {
  background-color: rgb(255, 190, 69) !important;
  border-radius: 4px !important;
  margin: 4px;
}

/* .page-link {
  background-color: transparent !important;
  outline: none;
  color: black;
  border: none;
  border-radius: 0%;
} */
a.page-link {
  border: none;
}

.page-item.active .page-link {
  text-decoration: underline;
  border: none;
}

.page-link,
.page-item .page-link {
  background-color: transparent !important;
  color: white;
  border-radius: 4px !important;
  border-radius: 4px !important;
}

.page-item:hover,
.page-link:hover {
  color: white !important;
  text-decoration: underline;
}

.util-bar {
  z-index: 3;
  top: 0;
  /* left: 30%; */
  position: sticky;
  position: -webkit-sticky;
  opacity: 50%;
}