body {
  --text-color: #020208;
  --textsecondary-color: #5d5050;
  --texterciary-color: #ffffff;
  --texthighlight-color: #0084ff;
  --bkg-color: #fafafa;
  --bkgsecondary: #ffffff;
  --bkgterciary: rgba(170, 170, 170, 0.5);
  --bkgtranslucid: #020208;
  --bkgbtnupdate: #020208;
  --bkgdeleted: #e7e7e7;
  --selected-border-color: #020208;
  --border-color: #020208;
  --border-body-color: #020208;
  --bordersecondary-color: #c8c8c8;
  --borderselecetedsecondary-color: #0087fb;
  --lines-color: #d8d8d8;
  --bkg-button-on: #0087fb;
  --bkg-button-off: #f7f8f9;
  --box-shadow-color: rgba(0, 0, 0, 0.04);
  --bkr-report-closed-color: #4332f0;
  --bkr-report-open-color: #3edb9f;
  --border-circlelist-color: #0087fb;
  --bkg-circlelist-color: #fff;
  --bkr-arrow-negative: #ff3458;
  --bkr-arrow-positive: #3edb9f;
  --bkr-hover-color: #0087fb;
  --bkr-focus-color: #0086fb62;
  --bkr-purple: #c24dd6;
  --bkr-green: #3edb9f;
  --bkr-blue: #0087fb;
  --bkr-red: #ff3458;
}

body.dark {
  --text-color: #ffffff;
  --textsecondary-color: #acabad;
  --texterciary-color: #ffffff;
  --texthighlight-color: #ffffff;
  --bkg-color: #020208;
  --bkgsecondary: #2a2e32;
  --bkgterciary: rgba(170, 170, 170, 0.5);
  --bkgtranslucid: #020208;
  --bkgbtnupdate: #020208;
  --bkgdeleted: #e7e7e72f;
  --selected-border-color: #fff;
  --border-color: #fff;
  --border-body-color: #020208;
  --bordersecondary-color: #c8c8c8;
  --borderselecetedsecondary-color: #fff;
  --lines-color: #d8d8d8;
  --bkg-button-on: #020208;
  --bkg-button-off: #f7f8f9;
  --box-shadow-color: rgba(0, 0, 0, 0.04);
  --bkr-report-closed-color: #c24dd6;
  --bkr-report-open-color: #3edb9f;
  --border-circlelist-color: #020208;
  --bkg-circlelist-color: #fff;
  --bkr-arrow-negative: #ff3458;
  --bkr-arrow-positive: #3edb9f;
  --bkr-hover-color: #0087fb;
  --bkr-focus-color: #0086fb62;
  --bkr-purple: #c24dd6;
  --bkr-green: #3edb9f;
  --bkr-blue: #0087fb;
  --bkr-red: #ff3458;
  --color-scheme: light;
}

body.fire {
  --text-color: #ffffff;
  --textsecondary-color: #acabad;
  --texterciary-color: #ffffff;
  --texthighlight-color: #ffffff;
  --bkg-color: #212529;
  --bkgsecondary: #2a2e32;
  --bkgterciary: #aaa;
  --bkgtranslucid: #020208;
  --bkgbtnupdate: #020208;
  --bkgdeleted: #e7e7e72f;
  --selected-border-color: #fd413c;
  --border-color: #fff;
  --border-body-color: #212529;
  --bordersecondary-color: #c8c8c8;
  --borderselecetedsecondary-color: #fd413c;
  --lines-color: #d8d8d8;
  --bkg-button-on: #020208;
  --bkg-button-off: #f7f8f9;
  --box-shadow-color: rgba(0, 0, 0, 0.04);
  --bkr-report-closed-color: #febc2c;
  --bkr-report-open-color: #fd413c;
  --border-circlelist-color: #020208;
  --bkg-circlelist-color: #fff;
  --bkr-arrow-negative: #fd413c;
  --bkr-arrow-positive: #febc2c;
  --bkr-hover-color: #fd413c;
  --bkr-focus-color: #fd423c4f;
  --bkr-purple: #c24dd6;
  --bkr-green: #3edb9f;
  --bkr-blue: #0087fb;
  --bkr-red: #ff3458;
  --color-scheme: light;
}

.background-secondary,
.modal-content
.MuiTableHead-root,
.MuiTableRow-root.MTableHeader-headerRow-13.MuiTableRow-head,
.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded, 
.MuiTableCell-root {
  color: var(--text-color) !important;
  background-color: var(--bkgsecondary) !important;
}

.background-tertiary{
  color: var(--text-color) !important;
  background-color: var(--bkgterciary) !important;
}

body.dark .logo,
body.fire .logo,
body.dark .plus,
body.fire .plus,
body.dark .btn-dark,
body.fire .btn-dark,
body.dark .btn-close,
body.fire .btn-close,
body.dark .btn-outline-dark,
body.fire .btn-outline-dark,
body.dark ::-webkit-calendar-picker-indicator,
body.fire ::-webkit-calendar-picker-indicator {
  filter: invert(1) !important;
}

.text-theme,
.form-label {
  color: var(--text-color) !important;
}

input.form-control,
select,
option,
textarea,
.input-group-text {
  background: none;
  background-color: var(--bkgsecondary) !important;
  color: var(--text-color) !important;
}

.asterisk-red {
  color: rgb(127 29 29) !important;
} 