.responsiveImg {
  width: 100%;
  height: auto;
}

.swatch {
  padding: 5px;
  height: 100%;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #ced4da;
  display: inline-block;
  cursor: pointer;
}
.swatch:active {
  border: 2px solid blue;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.label-small {
  font-size: 12px;
}

.boxImg {
  border-radius: 6px !important;
  box-shadow: -1px 2px 7px 7px var(--box-shadow-color);
}/*# sourceMappingURL=Hotspot.css.map */