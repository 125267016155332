.Box {
  border-radius: 0.25rem;
  padding: 2rem;
}

.sortable-list-item {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
  cursor: grab;
  list-style-type: none;
}

.sortable-list-item:active {
  cursor: grabbing !important;
}

.shown {
  max-height: 200px;
  opacity: 1;
  transition: all 0.35s ease-in-out;
  margin-top: 2%;
}

.hidden {
  max-height: 0;
  opacity: 0;
  transition: all 0.35s ease-in-out;
}

