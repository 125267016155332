.plus {
  border-radius: 50%;
  /* box-shadow: 0px 0px 20px rgb(71, 71, 71) ; */
  background: none;
  border: none;
  bottom: 0;
  right: 0;
  padding: 0;
  margin-bottom: 1%;
  transform: translateX(-100%);
  cursor: pointer;
}

.button-edit {
  cursor: pointer;
}


#form .form-label,
#form .form-check-label,
#formSection .form-label,
#formSection .form-check-label,
#formAddQuestionSection .form-label,
#formAddQuestionSection .form-check-label,
#formQuestionList .form-label,
#formQuestionList .form-check-label,
#formAssessment .form-check-label {
  font-size: 14px;
}

.input {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.select {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  /* -webkit-appearance: none; */
  /* appearance: none; */
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pin-field {
  content: "*";
  background-color: #f8f9fa;
  border: 1px solid var(--bkgterciary);
  border-radius: 0.3rem;
  font-size: 2rem;
  margin: 0.25rem;
  height: 3.5rem;
  outline: none;
  text-align: center;
  transition-duration: .25s;
  transition-property: background,color,border,box-shadow,transform;
  width: 3rem;
  
}
.pin-field:focus {
  border-color: rgb(0, 123, 255);
  outline: none;
  transform: scale(1.05);
}

.pin-field:invalid {
  animation: shake 3 linear 75ms;
  border-color: var(--bkr-red);
  box-shadow: 0 0 0.25rem rgba(220, 53, 69, 0.5);
}

.pin-field:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}