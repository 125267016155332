.Login {
  height: 100vh;
  width: 100vw;
  margin: 0;
}

.Login-container {
  height: auto;
  width: 517px;
  background-color: var(--bkgsecondary) !important;
  border-top: solid 4px var(--border-color) !important;
}

.box {
  border-radius: 6px !important;
  box-shadow: -1px 2px 7px 7px var(--box-shadow-color);
  background-color: var(--bkgsecondary) !important;
}

.error {
  color: var(--bkr-red) !important;
  font-size: 12px;
}

.success {
  color: var(--bkr-green) !important;
  font-size: 14px;
}

.link-font-size {
  font-size: 14px
}

.label-font-size {
  font-size: 14px;
}

.hover-pointer:hover {
  cursor: pointer;
}

.radio-pointer label:hover,
.radio-pointer input:hover {
  cursor: pointer;
}
