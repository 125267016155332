.note-list {
    list-style: none;
}

.note-list li::before {
    content: '';
    height: 100%;
    left: -1em;
    position: absolute;
    top: 1.4em;
    border: solid 1px var(--lines-color);
    background-color: var(--lines-color) !important;
}

.note-list li::after {
    border-radius: 50%;
    content: '';
    height: 1em;
    left: -1.5em;
    position: absolute;
    top: 0.3em;
    width: 1em;
    background-color: var(--bkg-circlelist-color) !important;
    border: solid 3px var(--border-circlelist-color);
}

