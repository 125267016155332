.header-paragraph {
  font-weight: 500;
}
.header-line {
  margin-left: 1%;
  vertical-align: middle;
  width: 20%;
}
.header-title {
  font-size: 60px;
  font-weight: bold;
}

.btn-text {
  outline: none;
  border: none !important;
  box-shadow: none !important;
  border-bottom: solid 2px var(--bordersecondary-color) !important;
  border-radius: 0;
}

.btn-text:hover {
  outline: none;
}

.btn-check:checked + .btn-text {
  outline: none;
  border-bottom: solid 2px var(--borderselecetedsecondary-color) !important;
  border-bottom: solid 2px var(--text-color) !important;
  color: var(--text-color) !important;
  font-weight: bold;
}

.top-25 {
  top: -25% !important;
}



/* .position-absolute.end-0.top-0.translate-y-middle.my-5.p-3 {
  position: relative !important;
  margin: 0 !important;
} */
