.assessment-component {
  height: 67vh;
}

.utility-bar {
  z-index: 3;
  bottom: 0;
  background-color: #fff;
  position: fixed;
  position: -webkit-sticky;
  border: 1px solid black;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.utility-button {
  border: 1px solid #7E7E7E;
  width: 8%;
}

.utility-button p {
  font-size: 12px;
  margin: 0;
  text-align: center;
}

.utility-button:hover {
  cursor: pointer;
}

.utility-button-small {
  border: 1px solid #7E7E7E;
  padding: 4px;
  border-radius: 100%;
}

.utility-button-small:hover {
  cursor: pointer;
}