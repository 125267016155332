/* .candidate-pin:first-child {

} */

.pin-field {
  width: 36px;
  height: 48px;
  font-size: 32px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid #d3d3d3;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
}

.pin-field:focus {
  outline: none;
  box-shadow: 0 0 7px #1957d2;
  border: 1px solid #1976d2;
  transform: scale(1.03);
}

.pin-field-complete {
  border: 1px solid #6ab04c;
  color: #6ab04c;
}

.pin-field-complete[disabled] {
  background: #6ab04c;
  opacity: 0.5;
  cursor: not-allowed;
}