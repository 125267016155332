.avatar-picture {
  border-radius: 50%;
}

.avatar-picture-message {
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.8);
  height: 157px;
  width: 157px;
  opacity: 0;
  font-size: 14px;
  transition: all 0.4s;
}

.navbar-avatar-picture {
  border-radius: 50%;
  height: 35px;
  width: 35px;
}

.avatar-picture-change {
  top: 120px;
  cursor: pointer;
  z-index: 9;
}

.avatar-picture-change:hover + .avatar-picture-message {
  opacity: 1;
}

#myProfile {
  height: 155px;
  width: 155px;
  min-height: 155px;
  min-width: 155px;
}

.bottom-border-2 {
  border-bottom: solid 2px var(--bordersecondary-color) !important;
}

.font-bold {
  font-weight: bold !important;
}

.font-secondary {
  color: var(--textsecondary-color) !important;
}

.highlight-blue {
  color: var(--texthighlight-color) !important;
}

/* cropper image */

#image {
  display: block;
  max-width: 100%;
}

.preview {
  overflow: hidden;
  width: 160px;
  height: 160px;
  margin: 10px;
  border: 1px solid var( --bkr-red);
}

@media only screen and (max-width: 980px) {
  .avatar-picture-message {
    display: none;
  }
}
